import { Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import OpCodeSearch from "./pages/OpCodeSearch";
import OpCodeCreate from "./pages/OpCodeCreate";
import OpCodeBulkCreate from "./pages/OpCodeBulkCreate";
import OpCodeSurvey from "./pages/OpCodeSurvey";
import DynamicImport from "./pages/UserStory/dynamicImport"; // Import the new dynamic component

function App() {
    return (
        <Routes>
            <Route index path="/" element={<Home />} />
            <Route path="/user-story/*" element={<DynamicImport />} />
            <Route path="/op-code-search" element={<OpCodeSearch />} />
            <Route path="/op-code-create" element={<OpCodeCreate />} />
            <Route path="/op-code-bulk-create" element={<OpCodeBulkCreate />} />
            <Route path="/op-code-survey" element={<OpCodeSurvey />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
}

export default App;
