import { useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";  // Import useNavigate and useLocation
import { Card, CardHeader, CardTitle, CardDescription, CardContent, CardFooter } from "../../components/ui/card";
import { Label } from "../../components/ui/label";
import { Input } from "../../components/ui/input";
import { Button } from "../../components/ui/button";

export default function Component() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);

  //const { StoreUserData } = useContext(UserStoryContext);
  const navigate = useNavigate();
  const location = useLocation();  // Initialize useLocation to get query params

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch("https://stgapp.coolriots.ai/bexo/token", {
        method: "POST",
        headers: {
          "accept": "application/json",
          "email": username,
          "password": password,
        },
        body: "",
      });

      if (response.ok) {
        const result = await response.json();

        // Store login data in localStorage
        localStorage.setItem("userData", JSON.stringify(result));  // Convert the result object to a string

        // Check if there is a redirect URL
        const redirectTo = new URLSearchParams(location.search).get("redirect");
        console.log("redirectTo",redirectTo)
        // If redirect URL exists, navigate to it; otherwise, navigate to Home
        navigate(redirectTo || "/user-story/training/ca?templateName=Dashbord");
      } else {
        const errorData = await response.json();
        setErrorMessage(errorData?.message || JSON.stringify(errorData));
      }
    } catch (error) {
      console.error("Error during login:", error);
      setErrorMessage(error.message || "An unknown error occurred.");
    }
  };

  return (
    <div className="flex min-h-[100dvh] items-center justify-center px-4 py-12 sm:px-6 lg:px-8">
      <Card className="w-full max-w-md">
        <CardHeader className="space-y-1">
          <CardTitle className="text-2xl font-bold">Login</CardTitle>
          <CardDescription>Enter your username and password to access your account.</CardDescription>
        </CardHeader>
        <CardContent className="space-y-4">
          <form onSubmit={handleSubmit}>
            <div className="space-y-2">
              <Label htmlFor="username">Email</Label>
              <Input
                id="username"
                type="text"
                placeholder="Your username"
                required
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div className="space-y-2">
              <div className="flex items-center justify-between">
                <Label htmlFor="password">Password</Label>
                <a href="/ForgotPassword" className="text-sm text-muted-foreground hover:underline">
                  Forgot password?
                </a>
              </div>
              <Input
                id="password"
                type="password"
                placeholder="Your password"  // Add the placeholder here
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            {errorMessage && <p className="text-red-500">{errorMessage}</p>}
            <Button type="submit" className="w-full">
              Sign In
            </Button>
          </form>
        </CardContent>
        <CardFooter className="text-center text-sm">
          Don&apos;t have an account?{" "}
          <a href="/Signup" className="font-medium underline">
            Sign up
          </a>
        </CardFooter>
      </Card>
    </div>
  );
}
