// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.op-code-create-heading {
    color: #b1092a;
    text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/pages/OpCodeCreate/op-code-create-styles.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,kBAAkB;AACtB","sourcesContent":[".op-code-create-heading {\n    color: #b1092a;\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
