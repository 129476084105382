import React from 'react';
// import { Button } from "@/components/ui/button";
import { Button } from "../components/ui/button"
const DownloadCSV = ({ jsonData, fileName }) => {
  console.log("DownloadCSV",jsonData)
  const jsonToCsv = (json) => {
    const keys = Object.keys(json[0]);
    const csvRows = [
      keys.join(','), // header row
      ...json.map(row => keys.map(key => row[key]).join(',')) // data rows
    ];
    return csvRows.join('\n');
  };

  const handleDownload = () => {
    const csvData = jsonToCsv(jsonData);
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', fileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <Button as="a" className="inline-flex items-center gap-2" onClick={handleDownload} variant="outline">
      <DownloadIcon className="h-4 w-4" />
      Download CSV
    </Button>
  );
};

function DownloadIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
      <polyline points="7 10 12 15 17 10" />
      <line x1="12" x2="12" y1="15" y2="3" />
    </svg>
  );
}

export default DownloadCSV;
