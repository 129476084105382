import React from "react";
import "./op-code-create-styles.css";
import OpCodeCreateForm from "../../components/OpCodeCreateForm";

export default function OpCodeCreate() {
    const urlParams = new URLSearchParams(window.location.search);
    const templateName = urlParams.get("templateName");

    return (
        <div>
            <h1 className="op-code-create-heading"> OpCode Create </h1>
            <OpCodeCreateForm templateName={templateName} />

            <OpCodeCreateForm
                                            templateName={templateName}
                                            prevFrame={false}
                                            isUserStory={false}
                                            filter={false}
                                            apiCalls={     [
                                                {
                                                    key: "templateJson",
                                                    onLoad: true,
                                                    onButton: false,
                                                    server: 2,
                                                },
                                                {
                                                    key: "bookingRegAPI",
                                                    onLoad: false,
                                                    onButton: true,
                                                    server: 3,
                                                },
                                            ]
                                        }
                                        />
        </div>
    );
}
