import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { BiChevronDown } from "react-icons/bi";

const DropDownBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    width: 100%;
    padding: 4px 4px;
    font-size: 14px;
    cursor: pointer;
    gap: 7px;

    &:hover {
        border: none;
        background-color: lightgrey;
        border-radius: 5px;
    }
`;

const DropDownBoxPlaceholder = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const DropDownItemContainer = styled.div`
    width: 100%;
`;

const DropDownItems = styled.div`
    position: absolute;
    z-index: 20000;
    background-color: white;
    box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.2);
    margin-top: 10px;
    border-radius: 5px;
    min-height: 40px;
    min-width: 150px;
    max-width: 270px;
`;

const DropDownSearchStyle = styled.input`
    width: calc(100% - 7%);
    margin: 5px;
    padding: 8px 10px;
`;

const DropDownItemStyle = styled.div`
    width: calc(100% - 7%);
    margin: 5px;
    cursor: pointer;
    &:hover {
        background-color: lightgrey;
    }
`;

const ItemText = styled.div`
    padding: 5px 10px;
`;

export const DropDownField = (props) => {
    const { item, formData, dispatch, isBulk } = props;
    const [showDropDown, setShowDropDown] = React.useState(false);
    const menuRef = useRef();

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setShowDropDown(false);
            }
        };

        document.addEventListener("click", handleOutsideClick);

        return () => {
            document.removeEventListener("click", handleOutsideClick);
        };
    });

    const setDropDownValue = (value) => {
        setShowDropDown(false);
        
        if (isBulk !== undefined && isBulk) {
            props.setFormDataForField(value);
        } else {console.log("setDropDownValue",value)
            dispatch({
                type: "SET_FORM_DATA",
                payload: { ...formData, [item?.key || item?.field]: value },
            });
        }
    };

    return (
        <div
            ref={menuRef}
            style={{
                width: `${item.width ? item.width : "100%"}`,
            }}
        >
            <DropDownBox onClick={() => setShowDropDown(!showDropDown)}>
                <DropDownBoxPlaceholder>
                    {formData[item.key || item.field]
                        ? formData[item.key || item.field]
                        : item.placeholder}
                </DropDownBoxPlaceholder>
                <div>
                    {showDropDown ? (
                        <BiChevronDown
                            className="drop-down-icon"
                            style={{
                                color: "black",
                                transform: "rotate(180deg)",
                            }}
                        />
                    ) : (
                        <BiChevronDown
                            className="drop-down-icon"
                            style={{ color: "black" }}
                        />
                    )}
                </div>
            </DropDownBox>
            <DropDownItemContainer
                style={{
                    // width: `${item.width ? item.width : "100%"}`,
                    boxShadow: `${
                        showDropDown === true
                            ? "0px 0px 5px 2px rgba(0, 0, 0, 0.2)"
                            : ""
                    }`,
                    position: `${
                        isBulk !== undefined && isBulk ? "" : "relative"
                    }`,
                }}
            >
                {showDropDown && (
                    <DropDownItems>
                        {showDropDown && (
                            <div>
                                {item.search && (
                                    <div>
                                        <DropDownSearchStyle
                                            type="text"
                                            placeholder="Search"
                                        />
                                    </div>
                                )}
                                {item.values &&
                                    item.values.map((value, index) => (
                                        <DropDownItemStyle
                                            key={index}
                                            onClick={() => {
                                                setDropDownValue(value);
                                            }}
                                        >
                                            <ItemText>{value}</ItemText>
                                        </DropDownItemStyle>
                                    ))}
                            </div>
                        )}
                    </DropDownItems>
                )}
            </DropDownItemContainer>
        </div>
    );
};
