import React from "react";
import styled from "styled-components";
import {
    AiOutlineVerticalRight,
    AiOutlineLeft,
    AiOutlineRight,
    AiOutlineVerticalLeft,
} from "react-icons/ai";

const PaginationContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    width: 100%;
    margin: auto;
    padding: 0px 40px;
    color: #bb0000;

    @media (max-width: 768px) {
        flex-direction: column;
        padding: 0px;
    }
`;

const RowNumberContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    @media (max-width: 768px) {
        display: none;
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
`;

const PageNumberContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 0px 10px;
`;

const ButtonStyle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const NumberOfRowsInput = styled.input`
    width: 12%;
    height: 30px;
    padding: 0px 5px;
    text-align: center;
    border: 1px solid #b0bec5;
    border-radius: 5px;

    &:disabled {
        background-color: #f5f5f5;
        color: "#111111";
        cursor: "not-allowed";
        font-weight: 500;
    }
`;

const activeButton = {
    color: "#bb0000",
    cursor: "pointer",
};

const disabledButton = {
    color: "#b0bec5",
    cursor: "not-allowed",
};

export const Pagination = ({
    currentPage,
    maxNumberOfRows = 20,
    totalFetchedRow,
    totalRows,
    totalPages,
    onPageChange,
    setFetching,
}) => {
    const isFirstPage = currentPage === 1;
    const isLastPage = currentPage === totalPages;

    // Calculate the range of pages to display (5 pages total)
    const pageRange = Math.min(totalPages, 5);

    // Calculate the starting and ending page numbers for the pagination
    let startPage = Math.max(currentPage - Math.floor(pageRange / 2), 1);
    let endPage = startPage + pageRange - 1;

    if (endPage > totalPages) {
        endPage = totalPages;
        startPage = Math.max(endPage - pageRange + 1, 1);
    }

    const pageNumbers = [];
    for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
    }

    return (
        <PaginationContainer>
            <RowNumberContainer>
                <div>Item Per Page</div>
                <NumberOfRowsInput
                    type="number"
                    value={maxNumberOfRows}
                    disabled={true}
                />
                <div>
                    {(currentPage - 1) * maxNumberOfRows + 1} -{" "}
                    {(currentPage - 1) * maxNumberOfRows + totalFetchedRow} of{" "}
                    {totalRows}
                </div>
            </RowNumberContainer>

            <ButtonContainer>
                <ButtonStyle
                    style={isFirstPage ? disabledButton : activeButton}
                    onClick={() => {
                        onPageChange(1);
                        setFetching(true);
                    }}
                >
                    <AiOutlineVerticalRight />
                </ButtonStyle>
                <ButtonStyle
                    style={isFirstPage ? disabledButton : activeButton}
                    onClick={() => {
                        onPageChange(currentPage - 1);
                        setFetching(true);
                    }}
                >
                    <AiOutlineLeft />
                    Previous
                </ButtonStyle>
                <PageNumberContainer>
                    {currentPage} of {totalPages}
                </PageNumberContainer>
                <ButtonStyle
                    style={isLastPage ? disabledButton : activeButton}
                    onClick={() => {
                        onPageChange(currentPage + 1);
                        setFetching(true);
                    }}
                >
                    Next
                    <AiOutlineRight />
                </ButtonStyle>
                <ButtonStyle
                    style={isLastPage ? disabledButton : activeButton}
                    onClick={() => {
                        onPageChange(totalPages);
                        setFetching(true);
                    }}
                >
                    <AiOutlineVerticalLeft />
                </ButtonStyle>
            </ButtonContainer>
        </PaginationContainer>
    );
};
