// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    --white-color: #ffffff;
    --black-color: #000000;
    --gray-color: #b0bec5;
    --bright-red-color: #bb0000;
    --dark-red-color: #b1092a;
    position: relative;
    z-index: 1;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT;;oCAEgC;IAChC,mCAAmC;IACnC,kCAAkC;;IAElC,sBAAsB;IACtB,sBAAsB;IACtB,qBAAqB;IACrB,2BAA2B;IAC3B,yBAAyB;IACzB,kBAAkB;IAClB,UAAU;AACd;;AAEA;IACI;iBACa;AACjB","sourcesContent":["body {\n    margin: 0;\n    font-family: -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\",\n        \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\",\n        \"Helvetica Neue\", sans-serif;\n    -webkit-font-smoothing: antialiased;\n    -moz-osx-font-smoothing: grayscale;\n\n    --white-color: #ffffff;\n    --black-color: #000000;\n    --gray-color: #b0bec5;\n    --bright-red-color: #bb0000;\n    --dark-red-color: #b1092a;\n    position: relative;\n    z-index: 1;\n}\n\ncode {\n    font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\",\n        monospace;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
