import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import GlobalState, { Context } from "../context/GlobalState";
import "../App.css";
import { AppLoader } from "../components/LoaderSpinner";
import Notifcation from "../components/Notifcation";
import { UserStoryState } from "../context/UserStoryState";

export default function Wrapper({ children }) {
    const store = GlobalState();

    return (
        <Context.Provider value={store}>
            <UserStoryState>
                <div className="container">
                    <Header />
                    <Notifcation />
                    <main className="body-container">
                        {store.loading ? (
                            <AppLoader size={30} />
                        ) : (
                            <>{children}</>
                        )}
                    </main>
                    <Footer />
                </div>
            </UserStoryState>
        </Context.Provider>
    );
}
