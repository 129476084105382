import React from "react";
import "./op-code-search-styles.css";
import OpCodeSearchForm from "../../components/OpCodeSearchForm";

export default function OpCodeSearch() {
    const urlParams = new URLSearchParams(window.location.search);
    const templateName = urlParams.get("templateName");

    return (
        <div>
            <h1 className="op-code-search-heading"> OpCode Search </h1>
            <OpCodeSearchForm templateName={templateName} />
        </div>
    );
}
