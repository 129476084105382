import styled, { keyframes } from "styled-components";
import { useContext } from "react";
import { Context } from "../context/GlobalState";
import { BsCheckCircle } from "react-icons/bs";
import { LuAlertCircle } from "react-icons/lu";
import { BiError } from "react-icons/bi";

const NotificationContainer = styled.div`
    position: fixed;
    display: flex;
    flex-direction: column;
    left: 0;
    right: 0;
    top: 0;
    margin: auto;
    margin-top: 20px;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    width: 500px;
    padding: 5px;
    height: auto;
    max-height: 400px;
    overflow: "hidden";
    gap: 10px;
    z-index: 10000;
`;
const AnimationEffect = keyframes`
    from {
        transform: translateY(-80px);
    }

    to {
        transform : translateY(0px);
    }
`;
const NotficationContent = styled.div`
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
    width: 400px;
    background-color: white;
    padding: 10px;
    border: ${(props) => {
        if (props.$type === "danger") {
            return "1px solid rgb(182, 11, 11)";
        } else if (props.$type === "success") {
            return "1px solid rgb(8, 114, 8)";
        } else {
            return "1px solid #aa4b07";
        }
    }};
    border-radius: 5px;
    animation: ${AnimationEffect} 0.2s ease-in;
`;

const IconContainer = styled.div`
    display: flex;
    align-items: center;
    color: ${(props) => {
        if (props.$type === "danger") {
            return "#b31c11";
        } else if (props.$type === "success") {
            return "rgb(47, 124, 47)";
        } else {
            return "#bd5c17";
        }
    }};
`;

const MessageContainer = styled.div`
    word-wrap: break-word;
    flex-grow: 1;
    max-width: 350px;
    min-height: 20px;
    max-height: 80px;
    overflow: hidden;
`;

const NotificationMessage = styled.span`
    font-size: 13px;
    font-weight: 400;
    word-wrap: break-word;
`;

export default function Notifcation() {
    const { notificationStatus } = useContext(Context);

    function routitIcons(type) {
        if (type === "danger") return <BiError color="#da291c" />;
        else if (type === "success")
            return <BsCheckCircle color="rgb(65, 160, 65)" />;
        else return <LuAlertCircle color="#ca6a25" />;
    }

    return (
        <NotificationContainer>
            {notificationStatus?.map((notification, i) => (
                <NotficationContent key={i} $type={notification.type}>
                    <IconContainer $type={notification.type}>
                        {routitIcons(notification.type)}
                    </IconContainer>
                    <MessageContainer>
                        <NotificationMessage>
                            {notification.message}
                        </NotificationMessage>
                    </MessageContainer>
                </NotficationContent>
            ))}
        </NotificationContainer>
    );
}
