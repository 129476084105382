import { useState } from "react";
import { Card, CardHeader, CardTitle, CardDescription, CardContent, CardFooter } from "../../components/ui/card";
import { Label } from "../../components/ui/label";
import { Input } from "../../components/ui/input";
import { Button } from "../../components/ui/button";

export default function Component() {
  // State to manage form input
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [tenantId, setTenantId] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(""); // Clear any previous errors

    // Payload to send to the server
    const payload = {
      email,
      password,
      scope: "mirror", // Fixed scope value as per your example
      user_type: "normal",
      tenantId:"1"
    };

    try {
      // Make the POST request to the server
      const response = await fetch("https://stgapp.coolriots.ai/bexo/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "accept": "application/json",
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();
      setLoading(false);

      if (response.ok) {
        // Handle success (e.g., redirect to login page or show success message)
        alert("Signup successful!");
        // Redirect to login or another page
        window.location.href = "/login";
      } else {
        // Display a general error message if signup fails
        setError("An error occurred. Please try again.");
      }
    } catch (err) {
      // Handle network or other errors
      setLoading(false);
      setError("An error occurred. Please try again.");
    }
  };

  return (
    <div className="flex min-h-[100dvh] items-center justify-center px-4 py-12 sm:px-6 lg:px-8">
      <Card className="w-full max-w-md">
        <CardHeader className="space-y-1">
          <CardTitle className="text-2xl font-bold">Sign Up</CardTitle>
          <CardDescription>Enter your details to create a new account.</CardDescription>
        </CardHeader>
        <CardContent className="space-y-4">
          {error && <p className="text-red-500 text-sm text-center">{error}</p>}
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="space-y-2">
              <Label htmlFor="username">Username</Label>
              <Input 
                id="username" 
                placeholder="JohnDoe" 
                required 
                value={email} 
                onChange={(e) => setEmail(e.target.value)} 
              />
            </div>
            <div className="space-y-2">
              <Label htmlFor="password">Password</Label>
              <Input 
                id="password" 
                type="password" 
                required 
                value={password} 
                onChange={(e) => setPassword(e.target.value)} 
              />
            </div>
            {/* <div className="space-y-2">
              <Label htmlFor="tenantId">Tenant ID</Label>
              <Input 
                id="tenantId" 
                placeholder="Enter Tenant ID" 
                required 
                value={tenantId} 
                onChange={(e) => setTenantId(e.target.value)} 
              />
            </div> */}
            <Button type="submit" className="w-full" disabled={loading}>
              {loading ? "Signing Up..." : "Sign Up"}
            </Button>
          </form>
        </CardContent>
        <CardFooter className="text-center text-sm">
          Already have an account?{" "}
          <a href="/login" className="font-medium underline" prefetch={false}>
            Sign in
          </a>
        </CardFooter>
      </Card>
    </div>
  );
}
