import React, { Suspense, useState, useEffect } from "react";
import { useSearchParams, useParams, useLocation } from "react-router-dom";

const DynamicImport = () => {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const templateName = searchParams.get("templateName");
  const [UserStoryComponent, setUserStoryComponent] = useState(null);

  useEffect(() => {
    const importUserStoryComponent = async () => {
      try {
        const folderName = location.pathname.replace('/user-story/', ''); // Remove the /user-story/ part
        if (folderName && templateName) {
          const { default: UserStory } = await import(`../${folderName}/${templateName}`);
          setUserStoryComponent(() => UserStory);
        } else if (templateName) {
          const { default: UserStory } = await import(`../${templateName}`);
          setUserStoryComponent(() => UserStory);
        } else {
          const { default: DefaultUserStory } = await import(`../`);
          setUserStoryComponent(() => DefaultUserStory);
        }
      } catch (error) {
        console.error("Error loading component", error);
      }
    };

    importUserStoryComponent();
  }, [location.pathname, templateName]);

  if (!UserStoryComponent) {
    return <div>Loading...</div>;
  }

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <UserStoryComponent />
    </Suspense>
  );
};

export default DynamicImport;
