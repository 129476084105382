import React, { createContext, useState } from "react";

export const UserStoryContext = createContext();

export const UserStoryState = ({ children }) => {
    const [contextData, setContextData] = useState([]);
    const [viewDetailRecordID, setViewDetailRecordID] = useState("");
    const [viewDetailTableName, setViewDetailTableName] = useState("");

    

    // const AddContextData = (dataToAdd) => {
    //     if (typeof dataToAdd !== 'object') {
    //         console.error("Invalid input. Expected an object.");
    //         return;
    //     }
    
    //     const keys = Object.keys(dataToAdd);
    
    //     keys.forEach(key => {
    //         const index = contextData.findIndex(item => item.hasOwnProperty(key));
    //         const filteredData = index === -1 ? contextData : contextData.slice(0, index);
    //         const temp = [...filteredData, { [key]: dataToAdd[key] }];
            
    //         setContextData(temp);
    //         console.log("temp_context", temp);
    //     });
    // };
    const AddContextData = (dataToAdd) => {
        if (typeof dataToAdd !== 'object') {
            console.error("Invalid input. Expected an object.");
            return;
        }
    
        // Create a copy of the current contextData
        let updatedContextData = [...contextData];
    
        const keys = Object.keys(dataToAdd);
    
        keys.forEach(key => {
            const index = updatedContextData.findIndex(item => item.hasOwnProperty(key));
            if (index === -1) {
                // If the key does not exist, add a new entry
                updatedContextData.push({ [key]: dataToAdd[key] });
            } else {
                // If the key exists, update its value
                updatedContextData[index][key] = dataToAdd[key];
            }
        });
    
        // Update the state with the modified contextData
        setContextData(updatedContextData);
        console.log("Updated contextData:", updatedContextData);
    };
    

    const GetPreviousContextData = () => {
        return contextData.length > 0 ? contextData : null;
    };
    const SetViewDetailData = (tableName, RecordID) => {
        console.log(tableName, RecordID);
        setViewDetailTableName(tableName);
        setViewDetailRecordID(RecordID);
    };

    const ResetViewDetailData = () => {
        setViewDetailTableName("");
        setViewDetailRecordID("");
    };

    const contextValue = {
        contextData,
        AddContextData,
        GetPreviousContextData,
        SetViewDetailData,
        ResetViewDetailData,
        viewDetailTableName,
        viewDetailRecordID,
    };

    return (
        <UserStoryContext.Provider value={contextValue}>
            {children}
        </UserStoryContext.Provider>
    );
};
