import { createContext, useEffect, useState } from "react";

export const Context = createContext({});

export default function GlobalState() {
    const [loading, setLoading] = useState(true);
    const [notificationStatus, setNotificationStatus] = useState([]);

    // add New Notifcation
    const addNewNotifcation = (
        message,
        type = "success",
        timeremaining = 4,
    ) => {
        // type = ['success', 'danger', 'warning']
        setNotificationStatus((prev) => [
            ...prev,
            { message, type, timeremaining },
        ]);
    };

    useEffect(() => {
        const timeOut = setInterval(() => {
            // console.log('srrr');
            if (notificationStatus.length > 0) {
                setNotificationStatus((prev) =>
                    prev
                        .filter((i) => i.timeremaining > 0)
                        .map((item) => {
                            return {
                                ...item,
                                timeremaining: item.timeremaining - 0.2,
                            };
                        }),
                );
            }
        }, 200);

        if (notificationStatus.length <= 0) {
            clearInterval(timeOut);
        }

        return () => clearInterval(timeOut);
    }, [notificationStatus]);

    const actions = (action) => {
        const { type, payload } = action;

        switch (type) {
            case "SET_LOADING":
                return setLoading(payload);
            default:
                return loading;
        }
    };

    useEffect(() => {
        setLoading(false);
    }, []);

    return { actions, addNewNotifcation, loading, notificationStatus };
}
