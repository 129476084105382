/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useReducer, useState } from "react";
import { styled } from "styled-components";
import { httpRequest2, httpRequest3 } from "../utils/httpsRequest";
import AlertModal from "./Alertmodal";
import { Context } from "../context/GlobalState";
import { PrimaryButton } from "./Buttons";
import { DropDownField } from "./DropDown";

const OpCodeBulkCreateContainer = styled.div`
    border: 1px solid rgba(0, 0, 0, 0.2);
    background-color: #faf9f4;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 20px;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 20px;
    width: 100%;
`;

const TableScroll = styled.div`
    overflow-x: auto;
    overflow-y: hidden;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
        height: 10px;
        background-color: #888;
        border-radius: 20px;
        margin: 10px 0;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #bb0000;
        border-radius: 20px;
    }
`;

const TableContainer = styled.table`
    border-collapse: collapse;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 10px;
    padding: 10px;
    border-collapse: collapse;
`;

const TableHeaderContainer = styled.thead`
    background-color: #b1092a;
    font-weight: 500;
    color: white;
    position: relative;
`;

const TableHeader = styled.th`
    text-align: start;
    // border: 1px solid #ddd;
    border: none;
    padding: 8px 4px 8px 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: relative;
`;

const TableBodyContainer = styled.tbody`
    background-color: white;
`;

const TableRow = styled.tr`
    padding: 100px;
`;

const TableData = styled.td`
    text-align: start;
    padding: 8px 4px;
    white-space: nowrap;
    // vertical-align: top;
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
`;

const StyledInput = styled.input`
    border: 0px;
    // border-bottom: 1px solid rgba(240, 240, 240, 1);
    border-bottom: 2px solid rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    padding: 5px 0px;
    background-color: white;

    &:focus {
        outline: none;
        color: black;
        background-color: white;
        border-bottom: 2px solid #b1092a;
    }

    &::placeholder {
        color: black;
    }

    &[type="radio"] {
        & + label {
            color: #000 !important;
        }
    }
`;

const RadioContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
`;

const initialBulkCreateState = {
    template: {
        templateName: "",
        tableName: "",
        formComponent: {},
    },
    numberOfRows: 4,
    formData: Array.from({ length: 4 }, () => ({})),
    fieldErrors: Array.from({ length: 4 }, () => ({})),
};

const bulkCreateReducer = (state, action) => {
    switch (action.type) {
        case "SET_TEMPLATE":
            return { ...state, template: action.payload };
        case "SET_NUMBER_OF_ROWS":
            return { ...state, numberOfRows: action.payload };
        case "SET_FORM_DATA":
            return { ...state, formData: action.payload };
        case "CLEAR_FORM_DATA":
            return {
                ...state,
                formData: Array.from({ length: action.payload }, () => ({})),
            };
        case "SET_FIELD_ERRORS":
            return { ...state, fieldErrors: action.payload };
        default:
            return state;
    }
};

export default function OpCodeBulkCreateForm({ templateName }) {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const [state, dispatch] = useReducer(
        bulkCreateReducer,
        initialBulkCreateState,
    );

    const { addNewNotifcation } = useContext(Context);
    const { template, numberOfRows, formData, fieldErrors } = state;
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
    const [showAlert, setShowAlert] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);

    useEffect(() => {
        const getTemplate = async () => {
            await httpRequest2
                .get(`/api/template/${templateName}`)
                .then(async (response) => {
                    dispatch({
                        type: "SET_TEMPLATE",
                        payload: response.data,
                    });
                })
                .catch((error) => {
                    console.log(error);
                });
        };

        getTemplate();
    }, [templateName]);

    // Function to add new row after a specific row
    const addNewRowAfterSpecificRow = (rowIndex) => {
        dispatch({ type: "SET_NUMBER_OF_ROWS", payload: numberOfRows + 1 });
        dispatch({
            type: "SET_Fields_ERRORS",
            payload: [
                ...formData.slice(0, rowIndex + 1),
                {},
                ...formData.slice(rowIndex + 1),
            ],
        });
        dispatch({
            type: "SET_FORM_DATA",
            payload: [
                ...formData.slice(0, rowIndex + 1),
                {},
                ...formData.slice(rowIndex + 1),
            ],
        });
    };

    // Function to remove existing specific row
    const removeExistingRow = (rowIndex) => {
        if (numberOfRows === 1) return;
        dispatch({ type: "SET_NUMBER_OF_ROWS", payload: numberOfRows - 1 });
        dispatch({
            type: "SET_Fields_ERRORS",
            payload: [
                ...formData.slice(0, rowIndex),
                ...formData.slice(rowIndex + 1),
            ],
        });
        dispatch({
            type: "SET_FORM_DATA",
            payload: [
                ...formData.slice(0, rowIndex),
                ...formData.slice(rowIndex + 1),
            ],
        });
    };

    // Function to clear the form
    const handleClear = () => {
        dispatch({ type: "CLEAR_FORM_DATA", payload: numberOfRows });
    };

    // Function to delete the form all data after confirmation
    const handleDelete = () => {
        setShowAlert(true);
    };
    const handleConfirmDelete = async () => {
        setDeleteLoading(true);
        handleClear();
        setTimeout(() => {
            setDeleteLoading(false);
            setShowAlert(false);
        }, 100);
    };

    // Function to set form data for a specific field
    const setFormDataForField = (rowIndex, key, value) => {
        dispatch({
            type: "SET_FORM_DATA",
            payload: [
                ...formData.slice(0, rowIndex),
                { ...formData[rowIndex], [key]: value.trim() },
                ...formData.slice(rowIndex + 1),
            ],
        });
    };

    // Function to validate the form
    const validateForm = () => {
        const requiredFields = template.formComponent.fields.filter(
            (field) => field.required,
        );
        const newFieldErrors = Array.from({ length: numberOfRows }, () => ({}));

        formData.forEach((row, rowIndex) => {
            requiredFields.forEach((field) => {
                if (!row[field.field]) {
                    console.log(row[field.field]);
                    newFieldErrors[rowIndex][field.field] =
                        `${field.label} is required`;
                } else {
                    newFieldErrors[rowIndex][field.field] = "";
                }
            });
        });

        dispatch({ type: "SET_FIELD_ERRORS", payload: newFieldErrors });

        return Object.values(newFieldErrors).every((error) => error === "");
    };

    // Function to create payload
    const createPayload = (data, tableName) => {
        if (
            data &&
            tableName &&
            Object.keys(data).length > 0 &&
            tableName !== ""
        ) {
            const payLoad = {
                Meta: {
                    Child: {
                        Name: tableName,
                        Type: "Child Create",
                    },
                    Action: "create",
                },
                ChildRecords: [data],
            };
            return payLoad;
        }
    };

    // Function to handle submit
    const handleSubmit = async () => {
        const isValid = validateForm();
        console.log(isValid);
        if (isValid) {
            console.log(formData);
            const createPayloadData = createPayload(
                formData,
                template.formComponent.tadabaseTableName,
            );
            console.log("created:", createPayloadData);

            await httpRequest3
                .post(`/beso/actions/bex-action/tb`, createPayloadData)
                .then(async (response) => {
                    if (response.data) {
                        // const data = response.data.payload;
                        console.log(response.data);
                        if (response.data.bulkCount.success === 1) {
                            addNewNotifcation(
                                "Record Created Successfully",
                                "success",
                            );
                            handleClear();
                        } else {
                            addNewNotifcation(
                                "Record Creation Failed",
                                "danger",
                            );
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                    addNewNotifcation("Record Creation Failed", "danger");
                });
        }
    };

    // UseEffect to check if all required fields are filled or not and disable submit button accordingly
    useEffect(() => {
        if (template && template.tableName !== "") {
            const requiredFields = template.formComponent.fields.filter(
                (field) => field.required,
            );

            const isAllRequiredFieldsFilled = formData.every((row) => {
                return requiredFields.every((field) => row[field.field]);
            });

            console.log(isAllRequiredFieldsFilled);

            setIsSubmitDisabled(!isAllRequiredFieldsFilled);
        }
    }, [formData]);

    // console.log(formData);

    return (
        <>
            {template &&
            template?.formComponent &&
            template?.formComponent?.isBulk ? (
                <OpCodeBulkCreateContainer>
                    <TableScroll>
                        <TableContainer>
                            <TableHeaderContainer>
                                <TableRow>
                                    <TableHeader></TableHeader>
                                    <TableHeader></TableHeader>
                                    {template?.formComponent?.fields.map(
                                        (item, index) => {
                                            return (
                                                <>
                                                    {item.visible !== false && (
                                                        <TableHeader
                                                            key={index}
                                                        >
                                                            <TableData
                                                                style={{
                                                                    minWidth: `${item.width}`,
                                                                }}
                                                            >
                                                                {item.label}{" "}
                                                                {item.required ? (
                                                                    <span className="required">
                                                                        *
                                                                    </span>
                                                                ) : null}
                                                            </TableData>
                                                        </TableHeader>
                                                    )}
                                                </>
                                            );
                                        },
                                    )}
                                </TableRow>
                            </TableHeaderContainer>
                            <TableBodyContainer>
                                {Array.from(
                                    { length: numberOfRows },
                                    (_, rowIndex) => (
                                        <TableRow key={rowIndex}>
                                            <TableData>
                                                <button
                                                    onClick={() => {
                                                        addNewRowAfterSpecificRow(
                                                            rowIndex,
                                                        );
                                                    }}
                                                >
                                                    +
                                                </button>
                                            </TableData>
                                            <TableData>
                                                <button
                                                    onClick={() => {
                                                        removeExistingRow(
                                                            rowIndex,
                                                        );
                                                    }}
                                                >
                                                    -
                                                </button>
                                            </TableData>
                                            {template?.formComponent?.fields.map(
                                                (item, index) => {
                                                    return (
                                                        <>
                                                            {item.visible !==
                                                                false && (
                                                                <TableData
                                                                    key={index}
                                                                    style={{
                                                                        width: `${item.width}`,
                                                                        maxWidth: `${item.width}`,
                                                                    }}
                                                                >
                                                                    {[
                                                                        "text",
                                                                        "number",
                                                                        "date",
                                                                        "email",
                                                                    ].includes(
                                                                        item.inputType,
                                                                    ) && (
                                                                        <StyledInput
                                                                            placeholder={
                                                                                item.placeholder
                                                                            }
                                                                            type={
                                                                                item.inputType
                                                                            }
                                                                            id={
                                                                                item.key
                                                                            }
                                                                            name={
                                                                                item.key
                                                                            }
                                                                            value={
                                                                                formData[
                                                                                    rowIndex
                                                                                ][
                                                                                    item
                                                                                        .field
                                                                                ] ||
                                                                                ""
                                                                            }
                                                                            onChange={(
                                                                                event,
                                                                            ) =>
                                                                                setFormDataForField(
                                                                                    rowIndex,
                                                                                    item.field,
                                                                                    event
                                                                                        .target
                                                                                        .value,
                                                                                )
                                                                            }
                                                                            style={{
                                                                                width: `${
                                                                                    item?.width
                                                                                        ? item?.width
                                                                                        : "100%"
                                                                                }`,
                                                                            }}
                                                                        />
                                                                    )}
                                                                    {item.inputType ===
                                                                        "dropdown" && (
                                                                        <DropDownField
                                                                            item={
                                                                                item
                                                                            }
                                                                            formData={
                                                                                formData[
                                                                                    rowIndex
                                                                                ]
                                                                            }
                                                                            isBulk={
                                                                                true
                                                                            }
                                                                            setFormDataForField={(
                                                                                value,
                                                                            ) =>
                                                                                setFormDataForField(
                                                                                    rowIndex,
                                                                                    item.field,
                                                                                    value,
                                                                                )
                                                                            }
                                                                            style={{
                                                                                position:
                                                                                    "absolute",
                                                                                zIndex: 1,
                                                                            }}
                                                                        />
                                                                    )}
                                                                    {item.inputType ===
                                                                        "radio" && (
                                                                        <RadioContainer
                                                                            id={
                                                                                item.key ||
                                                                                item.field
                                                                            }
                                                                            style={{
                                                                                display:
                                                                                    "flex",
                                                                                flexDirection:
                                                                                    "row",
                                                                                flexWrap:
                                                                                    "wrap",
                                                                            }}
                                                                        >
                                                                            {item.values.map(
                                                                                (
                                                                                    value,
                                                                                    index,
                                                                                ) => (
                                                                                    <div
                                                                                        key={
                                                                                            index
                                                                                        }
                                                                                        style={{
                                                                                            display:
                                                                                                "flex",
                                                                                            flexDirection:
                                                                                                "row",
                                                                                            alignItems:
                                                                                                "center",
                                                                                            justifyContent:
                                                                                                "flex-start",
                                                                                            flexWrap:
                                                                                                "wrap",
                                                                                        }}
                                                                                    >
                                                                                        <StyledInput
                                                                                            type="radio"
                                                                                            id={
                                                                                                value
                                                                                            }
                                                                                            name={
                                                                                                item?.field
                                                                                            }
                                                                                            value={
                                                                                                formData[
                                                                                                    rowIndex
                                                                                                ][
                                                                                                    item
                                                                                                        .field
                                                                                                ] ||
                                                                                                ""
                                                                                            }
                                                                                            checked={
                                                                                                formData[
                                                                                                    item
                                                                                                        ?.field
                                                                                                ] ===
                                                                                                value
                                                                                            }
                                                                                            onChange={(
                                                                                                event,
                                                                                            ) => {
                                                                                                setFormDataForField(
                                                                                                    rowIndex,
                                                                                                    item?.field,
                                                                                                    event
                                                                                                        .target
                                                                                                        .value,
                                                                                                );
                                                                                            }}
                                                                                        />
                                                                                        <label
                                                                                            htmlFor={
                                                                                                value
                                                                                            }
                                                                                        >
                                                                                            {
                                                                                                value
                                                                                            }
                                                                                        </label>
                                                                                    </div>
                                                                                ),
                                                                            )}
                                                                        </RadioContainer>
                                                                    )}
                                                                </TableData>
                                                            )}
                                                        </>
                                                    );
                                                },
                                            )}
                                        </TableRow>
                                    ),
                                )}
                            </TableBodyContainer>
                        </TableContainer>
                    </TableScroll>
                    <ButtonContainer className="opcode-bulk-create-button-container">
                        <PrimaryButton
                            $text="Submit"
                            onClick={handleSubmit}
                            disabled={isSubmitDisabled}
                        />
                        <PrimaryButton
                            $text="Clear"
                            onClick={() => {
                                handleDelete();
                            }}
                            disabled={false}
                        />
                        <AlertModal
                            onDelete={handleConfirmDelete}
                            openModal={showAlert}
                            setOpenModal={setShowAlert}
                            loading={deleteLoading}
                            title="Are you sure you want to clear the form"
                            message="Once cleared, this form will be empty."
                            buttonText="Clear"
                        />
                    </ButtonContainer>
                </OpCodeBulkCreateContainer>
            ) : (
                <div>
                    <p>Loading...</p>
                </div>
            )}
        </>
    );
}
