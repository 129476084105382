/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useContext, useEffect } from "react";
import { css, styled } from "styled-components";

const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    display: flex; /* Centers modal content */
    justify-content: center; /* Horizontally center content */
    align-items: center; /* Vertically center content */
`;

const ModalWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 25px auto;
    transition: transform 0.5s ease;
    ${({ $isOpen }) =>
        !$isOpen &&
        css`
            transform: translateY(-100%);
            background-color: transparent;
        `};
`;

const ModalContent = styled.div`
    background-color: #fff;
    display: inline-block;
    width: 600px; /* Fixed width */
    max-width: 90%; /* Ensures responsiveness */
    height: auto;
    max-height: 80vh; /* Maximum height for responsiveness */
    transition: transform 0.5s ease;
    ${({ $isOpen }) =>
        $isOpen
            ? css`
                  transform: translateY(0%);
              `
            : css`
                  transform: translateY(-100%);
                  opacity: 0.5;
              `};
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 0.3rem;
    overflow-y: auto;
    ::-webkit-scrollbar {
        display: none;
    }
`;

const ModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    padding: 1rem;
`;

const ModalTitle = styled.h3`
    font-weight: 700;
    font-size: 25px;
    color: black;
`;

const ModalButton = styled.button`
    cursor: pointer;
    padding: 6px 12px;
    background-color: red;
    border: none;
    color: white;
    &:hover {
        font-weight: 700;
        background-color: rgba(255, 0, 0, 0.6);
    }
    font-size: 14px;
    font-weight: 600;
    border-radius: 4px;
`;

const ModalBody = styled.div`
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    padding: 1rem;
    min-height: 150px;
    max-height: 75vh;
    overflow: auto;
`;

const ModalFooter = styled.div`
    padding: 1rem;
`;

const ModalContext = createContext();

function Modal({
    children,
    $isOpen,
    setOpenModal,
    onClose = () => {},
    entityModal,
}) {
    const onModalClose = () => {
        setOpenModal(false);
        onClose() && onClose();
    };

    useEffect(() => {
        if (entityModal) return;
        if ($isOpen) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [$isOpen]);

    return $isOpen ? ( // Only render the modal overlay when $isOpen is true
        <ModalOverlay $isOpen={$isOpen} onClick={onModalClose}>
            <ModalWrapper $isOpen={$isOpen}>
                <ModalContent
                    $isOpen={$isOpen}
                    onClick={(e) => e.stopPropagation()}
                >
                    <ModalContext.Provider value={onModalClose}>
                        {children}
                    </ModalContext.Provider>
                </ModalContent>
            </ModalWrapper>
        </ModalOverlay>
    ) : null;
}

const Header = ({ children }) => {
    const onModalClose = useContext(ModalContext);

    return (
        <ModalHeader>
            <ModalTitle>{children}</ModalTitle>
            <ModalButton onClick={onModalClose}>X</ModalButton>
        </ModalHeader>
    );
};

const BodyModal = ({ children }) => {
    return <ModalBody>{children}</ModalBody>;
};

const Footer = ({ children }) => {
    return <ModalFooter>{children}</ModalFooter>;
};

Modal.Header = Header;
Modal.Body = BodyModal;
Modal.Footer = Footer;

export default Modal;