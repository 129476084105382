import React from "react";
import { styled } from "styled-components";
import { Card } from "./ui/card";

const CardContainer = styled.div`
    border: 1px solid rgba(0, 0, 0, 0.2);
    background-color: #fffff;
    box-sizing: border-box;
    border-radius: 15px;
    padding: 20px;
    margin-bottom: 20px;
`;

const CardBodyContainer = styled.div``;

const FooterContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 20px;
    gap: 20px;
    
    & > div {
        margin-bottom: 2%;
    }
`;

export default function Card2({ header, body, footer, footerCenter = true }) {
    return (
        <Card>
            <CardBodyContainer>
                <div>{header}</div>
                <div>{body}</div>
            </CardBodyContainer>
            <FooterContainer
                className="card-footer"
                style={{ flexDirection: `${footerCenter ? "row" : "column"}` }}
            >
                {footer &&
                    footer.map((item, index) => <div key={index}>{item}</div>)}
            </FooterContainer>
        </Card>
    );
}

