import React, {
    useState,
    useEffect,
    useRef,
    useContext
} from "react"
import { Scene } from '@soulmachines/smwebsdk';



const Avatar = () => {
    const videoEl = useRef(null);
    const sceneRef = useRef(null); // Using useRef to keep track of the scene instance

    useEffect(() => {
        if (videoEl.current) {
            const apiKey = 'eyJzb3VsSWQiOiJkZG5hLWNvb2xyaW90c2NhMTQtLWJleGZlbyIsImF1dGhTZXJ2ZXIiOiJodHRwczovL2RoLnNvdWxtYWNoaW5lcy5jbG91ZC9hcGkvand0IiwiYXV0aFRva2VuIjoiYXBpa2V5X3YxXzQyMjc3NWQyLWRhYTQtNGQ0Yi04Y2JlLTQ0YjZiMWZhMmFiNCJ9'; // Replace with your actual API key
            const scene = new Scene({
                apiKey: apiKey,
                videoElement: videoEl.current,
                requestedMediaDevices: { microphone: true },
                requiredMediaDevices: { microphone: true },
            });

            sceneRef.current = scene;

            const onConnectionSuccess = (sessionId) => {
                console.info('success! session id:', sessionId);

                // Start video automatically if required
                scene.startVideo()
                    .then(videoState => console.info('started video with state:', videoState))
                    .catch(error => console.warn('could not start video:', error));

                // Activate microphone
                scene.setMediaDeviceActive({ microphone: true })
                    .then(() => console.log('Microphone activated'))
                    .catch(error => console.error('Error changing media device state:', error));
            };

            const onConnectionError = (error) => {
                switch (error.name) {
                    case 'noUserMedia':
                        console.warn('User blocked device access or devices are not available.');
                        break;
                    case 'noScene':
                    case 'serverConnectionFailed':
                        console.warn('Server connection failed.');
                        break;
                    default:
                        console.warn('Unhandled error:', error);
                }
            };

            scene.connect()
                .then(sessionId => onConnectionSuccess(sessionId))
                .catch(error => onConnectionError(error));

            // Cleanup function to disconnect the scene
            return () => {
                const currentScene = sceneRef.current;

                if (currentScene && currentScene.isConnected()) {
                    if (typeof currentScene.disconnect === 'function') {
                        currentScene.disconnect()
                            .then(() => {
                                console.log('Disconnected successfully.');
                                sceneRef.current = null;  // Clear the reference after disconnect
                            })
                            .catch(error => console.error('Error during disconnect:', error));
                    } else {
                        console.warn('disconnect() is not a function on scene.');
                    }
                } else {
                    console.log('Scene is not connected, skipping disconnect.');
                }
            };
        }
    }, [videoEl.current]);

    const widgetContainerRef = useRef(null);

    return (
        <div>
            avatar
            <video
                ref={videoEl}
                id="sm-video"
                width="100%" height="100%"
            ></video>
        </div>
    )
}

export default Avatar