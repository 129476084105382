import axios from "axios";

// Server is for BeX-Orchestrator API (Dropdown API)
export const server =
    "https://prod-besorchestrator.bravebay-89a70f90.southeastasia.azurecontainerapps.io";

export const httpRequest = axios.create({
    withCredentials: true,
    baseURL: server,
});

// Server 1 is for BeX-Insight API
// export const server1 = "https://besinsight-test.salmonstone-ff69e304.southeastasia.azurecontainerapps.io";
export const server1 = "https://stgapp.coolriots.ai/bexo"
// export const server1 = "https://3aa9-103-142-80-38.ngrok-free.app/bexo"
export const httpRequest1 = axios.create({
    withCredentials: true,
    baseURL: server1,
});

// Server 2 is for Test API
// export const server2 = "https://besca-server.vercel.app";
export const server2 = "https://stgapp.coolriots.ai/feo-v3";

export const httpRequest2 = axios.create({
    withCredentials: true,
    baseURL: server2,
});

// Server 3 is for BeX-Action API
// export const server3 = "https://bexo.besorchestrator.app";
export const server3 = "https://stgapp.coolriots.ai";
export const httpRequest3 = axios.create({
    withCredentials: true,
    baseURL: server3,
    
});
