import React from "react";
import "./styles.css";
import { Button } from "../components/ui/button";
import logo from "../layout/ascendo header logo.png";
import { FaRegUser } from "react-icons/fa";

export default function Header() {
    return (
        <header className="bg-background border-b border-border flex items-center justify-between px-4 lg:px-6 h-14">
            <div className="flex items-center gap-2">
                <img src={logo} alt="Logo" className="h-15 w-auto" />
            </div>
            <div>
                <div className="flex items-center gap-2">
                    <Button className="inline-flex items-center justify-center rounded-md bg-gray-50 px-4 py-2 text-sm font-medium text-gray-900 shadow-sm transition-colors hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-[#0b236b] focus:ring-offset-2 dark:bg-gray-900 dark:text-gray-50 dark:hover:bg-gray-800 dark:focus:ring-gray-300">
                        Login &nbsp; <FaRegUser />
                    </Button>
                </div>
            </div>
        </header>
    );
}
