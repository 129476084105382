/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { httpRequest2 } from "../utils/httpsRequest";
import { CreateForm } from "./Form";
import { PrimaryButton } from "./Buttons";
import { AppLoader } from "./LoaderSpinner";
import Card from "./Card";

const initialUpdateState = {
    template: {
        templateName: "",
        tableName: "",
        formComponent: {},
    },
    formData: {},
    data: {},
    loading: true,
};

const updateReducer = (state, action) => {
    switch (action.type) {
        case "SET_TEMPLATE":
            return { ...state, template: action.payload };
        case "SET_DATA":
            return { ...state, data: action.payload };
        case "SET_FORM_DATA":
            return { ...state, formData: action.payload };
        case "SET_LOADING":
            return { ...state, loading: action.payload };
        default:
            return state;
    }
};

export default function OpCodeEditForm({ templateName, tableName, recordId }) {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [state, dispatch] = React.useReducer(
        updateReducer,
        initialUpdateState,
    );
    const { template, formData, data, loading } = state;

    const searchPayload = (templateData, formData, page) => {
        if (
            templateData?.tableName !== "" &&
            templateData?.searchComponent.fields.length > 0 &&
            templateData?.tableComponent.tableCol
        ) {
            const payLoad = {
                target: templateData.tableName,
                action: "search",
                filters: {
                    logicalOperator:
                        templateData.searchComponent.logicalOperator,
                    rules:
                        formData && Object.keys(formData).length > 0
                            ? templateData.searchComponent.fields
                                  .map((field) => {
                                      if (formData[field.field]) {
                                          const rule = {
                                              field: field.field,
                                              dataType: field.dataType,
                                              comparisonOperator:
                                                  field.comparisonOperator,
                                              value: formData[field.field],
                                          };
                                          if (field.foreignTable) {
                                              rule.foreignTable =
                                                  field.foreignTable;
                                          }
                                          if (field.foreignField) {
                                              rule.foreignField =
                                                  field.foreignField;
                                          }
                                          return rule;
                                      }
                                  })
                                  .filter((rule) => rule !== undefined)
                            : [
                                  {
                                      field: "recordId",
                                      dataType: "string",
                                      comparisonOperator: "notEquals",
                                      value: "null",
                                  },
                              ],
                },
                columnsToRetrieve: [
                    {
                        field: "recordId",
                        dataType: "string",
                    },
                    ...(templateData?.tableComponent?.tableCol.map((item) => {
                        const column = {
                            field: item.field,
                            dataType: item.dataType,
                        };
                        if (item.foreignTable) {
                            column.foreignTable = item.foreignTable;
                        }
                        if (item.foreignField) {
                            column.foreignField = item.foreignField;
                        }
                        return column;
                    }) || []),
                ],
                resultDisplayOrder:
                    templateData.tableComponent.resultDisplayOrder,
                maxRowsPerPage: templateData.tableComponent.maxRowsPerPage,
                pageToRetrieve: page,
            };
            return payLoad;
        }
    };

    useEffect(() => {
        const getTemplate = async () => {
            // await httpRequest.get(`/bexo/insights/bex3d-templates/opcode/search/${templateName}`)
            await httpRequest2
                .get(`/api/template/${templateName}`)
                .then(async (response) => {
                    if (response.data) {
                        dispatch({
                            type: "SET_TEMPLATE",
                            payload: response.data,
                        });
                        console.log(response.data);
                        await httpRequest2
                            .post(`/api/getRecord`, {
                                tableName: tableName,
                                ID: recordId,
                            })
                            .then((response) => {
                                console.log(response.data);
                                dispatch({
                                    type: "SET_DATA",
                                    payload: response.data,
                                });
                                dispatch({
                                    type: "SET_FORM_DATA",
                                    payload: response.data,
                                });
                            })
                            .catch((err) => console.log(err));
                    }
                })
                .catch((err) => console.log(err));
            loading && dispatch({ type: "SET_LOADING", payload: false });
        };

        getTemplate();
    }, [recordId, tableName, templateName]);

    const handleClear = () => {
        dispatch({ type: "SET_FORM_DATA", payload: {} });
        dispatch({ type: "SET_FORM_DATA", payload: data });
    };

    return loading === false ? (
        <div>
            {template && template.templateName !== "" ? (
                template.formComponent &&
                template.formComponent.fields &&
                template.formComponent.fields.length > 0 && (
                    <Card
                        body={
                            <CreateForm
                                formData={formData}
                                fields={template.formComponent.fields}
                                windowWidth={windowWidth}
                                dispatch={dispatch}
                            />
                        }
                        footer={[
                            <PrimaryButton $text="Submit" disabled={false} />,
                            <PrimaryButton
                                $text="Clear"
                                onClick={handleClear}
                                disabled={false}
                            />,
                        ]}
                    />
                )
            ) : (
                <div>
                    <h1>Template Not Found</h1>
                </div>
            )}
        </div>
    ) : (
        <div>
            <AppLoader />
        </div>
    );
}
