import React, { useContext, useEffect } from "react";
import { AiFillCheckCircle } from "react-icons/ai";
import { CgRadioCheck } from "react-icons/cg";
import styled from "styled-components";
import { UserStoryContext } from "../context/UserStoryState";
import Modal from "./Modal";
import OpCodeEditForm from "./OpCodeEditForm";
import { LoaderSpinner } from "./LoaderSpinner";
import { Checkbox } from "./ui/checkbox";
import {
    TableHead,
    TableRow,
    TableHeader,
    TableCell,
    TableBody,
    Table,
} from "./ui/table";
import { Button } from "./ui/button";
import { MdOutlineModeEdit } from "react-icons/md";

const NoData = styled.div`
    width: 100%;
    font-size: 18px;
    text-align: center;
    margin: 20px 0;
    font-weight: bolder;
    padding: 20px;
`;

export default function OpCodeTable({
    OpCodeEditTemplateName,
    tableName,
    tableCol,
    tableData,
    totalFetchedRow = 0,
    tableDisplayOrder = "row",
    haveSelect = false,
    isFetching,
}) {
    const [toggleButtonId, setToggleButtonId] = React.useState(null);
    const [showModal, setShowModal] = React.useState(false);
    const [editRecordId, setEditRecordId] = React.useState(null);

    const { SetViewDetailData } = useContext(UserStoryContext);

    const handleRowClick = (userId) => {
        setToggleButtonId(userId);
        if (haveSelect) {
            SetViewDetailData(tableName, userId);
        }
    };

    useEffect(() => {
        if (showModal) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

        return () => {
            document.body.style.overflow = 'auto'; // Ensure overflow is reset when component unmounts
        };
    }, [showModal]);

    return (
        <div className="overflow-auto">
            <Table>
                {isFetching === false ? (
                    tableCol && totalFetchedRow > 0 ? (
                        <>
                            <TableHeader className="bg-[#F3F4F6] font-bold">
                                <TableRow>
                                    {tableCol && (
                                        <>
                                            {haveSelect && (
                                                <TableHead className="w-[32px] py-10"></TableHead>
                                            )}
                                            {tableCol.map((item, index) => (
                                                <TableHead
                                                    className="text-[#0B236B]"
                                                    key={index}
                                                    style={{
                                                        width: item.size,
                                                        maxWidth: item.size,
                                                    }}
                                                >
                                                    {item.title}
                                                </TableHead>
                                            ))}
                                            <TableHead></TableHead>
                                        </>
                                    )}
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {tableDisplayOrder === "row" &&
                                    tableData &&
                                    tableData.tableRow &&
                                    tableData.tableRow.length > 0 &&
                                    tableData.tableRow.map((item, itemIndex) => (
                                        <TableRow key={itemIndex}>
                                            {haveSelect && (
                                                <TableCell
                                                    style={{
                                                        width: "30px",
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    <div
                                                        onClick={() => handleRowClick(item.recordId)}
                                                        className="toggle-button"
                                                    >
                                                        {toggleButtonId === item.recordId ? (
                                                            <AiFillCheckCircle
                                                                style={{
                                                                    color: "#bb0000",
                                                                    fontSize: "23px",
                                                                }}
                                                            />
                                                        ) : (
                                                            <CgRadioCheck
                                                                style={{
                                                                    fontSize: "23px",
                                                                }}
                                                            />
                                                        )}
                                                    </div>
                                                </TableCell>
                                            )}
                                            {tableCol.map((tableItem, index) => (
                                                <TableCell
                                                    key={index}
                                                    style={{
                                                        width: tableItem.size,
                                                        maxWidth: tableItem.size,
                                                    }}
                                                >
                                                    {item[tableItem.field]}
                                                </TableCell>
                                            ))}
                                            <TableCell
                                                style={{
                                                    width: "50px",
                                                    textAlign: "center",
                                                }}
                                            >
                                                <Button
                                                    onClick={() => {
                                                        setShowModal((prev) => !prev);
                                                        setEditRecordId(item.recordId);
                                                    }}
                                                >
                                                    Edit
                                                    <MdOutlineModeEdit
                                                        className="ml-2 mr-0 h-4 w-4"
                                                        variant="secondary"
                                                    />
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                            {tableData && showModal && (
                                <Modal $isOpen={showModal} setOpenModal={setShowModal}>
                                    <Modal.Header>OP Code Edit Form</Modal.Header>
                                    <Modal.Body>
                                        <OpCodeEditForm
                                            recordId={editRecordId}
                                            templateName={OpCodeEditTemplateName}
                                            tableName={tableName}
                                        />
                                    </Modal.Body>
                                </Modal>
                            )}
                        </>
                    ) : (
                        <>
                            {tableData && tableData.length === 0 && <NoData>No data found!</NoData>}
                        </>
                    )
                ) : (
                    <NoData>
                        <LoaderSpinner />
                    </NoData>
                )}
            </Table>
        </div>
    );
};
